html,
#root {
  scroll-behavior: smooth;
}

.highlight {
  background-color: rgba(252, 198, 5, 0.4);
  font-style: normal;
}

.HW_widget_component_wiki-widget {
  top: 2px;
}

.HW_badge_cont {
  width: 20px !important;
  height: 20px !important;
  right: 0 !important;
  top: 0;
}

.HW_badge {
  border: 2px solid #fff !important;
  line-height: 13px !important;
  top: -4px !important;
}

.HW_frame_cont {
  top: 32px !important;
}
